import React from "react";
import { motion } from "framer-motion";

export default function About() {
  // Animation variants for text and image
  const textAnimation = {
    hidden: { x: -200, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  const imageAnimation = {
    hidden: { x: 200, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  return (
    <section id="about">
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
        <motion.div
          className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center"
          variants={textAnimation}
          initial="hidden"
          whileInView="visible"
          transition={{ type: "spring", stiffness: 10 }}
          viewport={{ once: true }}
        >
          <h1 className="title-font sm:text-5xl text-3xl mb-4 font-medium text-white">
            Robert Fenech Adami
          </h1>
          <h3 className="title-font sm:text-3xl text-xl mb-4 font-medium text-green-400">
            Data Science & Software Engineering
          </h3>
          <br className="hidden lg:inline-block" />
          <p className="mb-8 leading-relaxed text-justify">
            Welcome! I'm a Data Science Master's student with a background in
            Software Engineering, delving into the exciting intersections of
            data, technology, and their applications in the real world. My
            academic path has led me from the University of Malta to the
            Universiteit van Amsterdam. Along this journey, I've also earned a
            Data Science certification from Codecademy. Professionally, I've
            sharpened my tech skills in dual roles: as a Software Developer and
            as a Technical Account Manager. These experiences have been crucial
            in enhancing my proficiency in both front-end and back-end
            development, as well as in Machine Learning engineering. Beyond the
            realms of code and data models, I'm deeply interested in sports and
            gaming, and I'm always up for discussions about the latest tech
            trends. These interests not only keep me grounded but also often
            infuse fresh perspectives into my work. At my core, I love diving
            into challenging projects, blending creativity with practicality.
            I'm driven by the pursuit of innovative solutions and am committed
            to making a tangible impact in the world of technology and data.
          </p>
          <div className="flex justify-center">
            <a
              href="#contact"
              className="inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg"
            >
              Contact Me
            </a>
            <a
              href="#projects"
              className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg"
            >
              Personal Projects
            </a>
          </div>
        </motion.div>
        <motion.div
          className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6"
          variants={imageAnimation}
          initial="hidden"
          whileInView="visible"
          transition={{ type: "spring", stiffness: 20 }}
          viewport={{ once: true }}
        >
          <img
            className="object-cover object-center rounded-full"
            alt="hero"
            src="./images/profile.jpg"
          />
        </motion.div>
      </div>
    </section>
  );
}
