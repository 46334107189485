export const projects = [
  {
    title: "Gamehub",
    subtitle: "React & TypeScript",
    description: "Simple videogame directory making use of several APIs",
    image: "./images/gamehub.png",
    link: "https://rfa-gamehub.vercel.app/",
  },
  {
    title: "Viral Twitter Tweets",
    subtitle: "Data Visualization & Analysis",
    description: "Jupyter notebook analyzing viral Twitter (X) tweets",
    image: "./images/twitter-analysis.png",
    link: "https://github.com/robbyfa/twitter-analysis",
  },
];

export const skills = [
  "JAVA",
  "JavaScript",
  "React",
  "TypeScript",
  "Angular",
  "NodeJS",
  "SQL",
  "Python",
  "C#",
  "Machine Learning",
  "R",
  "Data Visualization",
  "Data Analytics",
  "Data Science",
  "Deep Learning",
  "Statistics",
  "Git",
  "Docker",
];

export const education = [
  {
    title: "Primary & Secondary",
    date: "2007 - 2018",
    location: "St. Michael's Foundation",
  },
];

export const images = [
  "./images/_DSC2720.JPG",
  "./images/5f1786f9-9b30-4343-a5e5-ac28d5a23b30.jpg",
  "./images/BBFC 787.jpg",
  "./images/12bfe9d1-b465-4240-910b-bec941fefabe.jpg",
  "./images/347d2260-729e-405e-a0d7-e915dff22a56.jpg",
  "./images/38954142-99be-4d0d-9b90-afc8067f6854.jpg",
  "./images/BB377F8F-D3E3-4139-8EB4-828A85292480.jpg",
  "./images/BBFC 834.jpg",
  "./images/db472e59-c85f-4dd1-a438-7e3c7e48de6c.jpg",
  "./images/IMG_7949.JPG",
];
