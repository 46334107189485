import { motion } from "framer-motion";
import { PhotographIcon } from "@heroicons/react/outline";
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { images } from "../data";

export default function Gallery() {
  // Define the animation variants

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
    2048: { items: 4 },
  };

  const items = images.map((image, index) => (
    <div
      className="item mx-1 p-1 border-2 border-blue-500 rounded-lg "
      key={index}
      data-value={index + 1}
      style={{ height: "200px" }}
    >
      <img
        src={image}
        alt={`Slide ${index + 1}`}
        className="object-cover w-full h-full rounded-lg "
      />
    </div>
  ));

  const carouselVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeInOut",
      },
    },
  };

  return (
    <section id="gallery">
      <div className="container px-5 py-10 mx-auto">
        <div className="text-center mb-10">
          <PhotographIcon className="w-10 inline-block mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
            Gallery
          </h1>
        </div>

        <motion.div
          variants={carouselVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <AliceCarousel
            mouseTracking
            items={items}
            infinite
            disableDotsControls
            autoPlay
            autoPlayInterval={2500}
            responsive={responsive}
            controlsStrategy="alternate"
            animationType="fadeout"
          />
        </motion.div>
      </div>
    </section>
  );
}
