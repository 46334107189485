import { motion } from "framer-motion";
import React from "react";
import { projects } from "../data";
import { CodeIcon } from "@heroicons/react/solid";

export default function Projects() {
  // Define the animation variants
  const cardVariants = {
    offscreen: {
      y: 50,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 3,
      },
    },
  };

  return (
    <section id="projects" className="text-gray-400 bg-gray-900 body-font">
      <div className="container px-5 py-10 mx-auto text-center lg:px-40">
        <div className="flex flex-col w-full mb-10">
          <CodeIcon className="mx-auto inline-block w-10 mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
            Personal Projects
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            I'm always on the hunt for new, exciting projects!
          </p>
          <p>Currently working on Bird Classification using Deep Learning!</p>
        </div>
        <div className="flex flex-wrap -m-4">
          {projects.map((project, index) => (
            <motion.a
              href={project.link}
              key={project.image}
              className="sm:w-1/2 w-full p-4"
              variants={cardVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="flex relative" style={{ height: "200px" }}>
                <img
                  alt="gallery"
                  className="absolute inset-0 w-full h-full object-cover object-center"
                  src={project.image}
                />
                <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-0 hover:opacity-100">
                  <h2 className="tracking-widest text-sm title-font font-medium text-green-400 mb-1">
                    {project.subtitle}
                  </h2>
                  <h1 className="title-font text-lg font-medium text-white mb-3">
                    {project.title}
                  </h1>
                  <p className="leading-relaxed">{project.description}</p>
                </div>
              </div>
            </motion.a>
          ))}
        </div>
      </div>
    </section>
  );
}
